import { ArrowRightStartOnRectangleIcon, BanknotesIcon, Bars3BottomLeftIcon, KeyIcon, RectangleStackIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import isDarkColor from "is-dark-color";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserImage from "src/assets/images/user.jpg";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import Image from "src/components/elements/Image";
import DropdownWrapper from "src/components/form/DropdownWrapper";
import Input from "src/components/form/Input";
import Modal from "src/components/shared/Modal";
import UserDropdown from "src/components/shared/UserDropdown";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import { setBrowserStyleProperties } from "src/helpers/setBrowserStyleProperties";
import ResetPasswordForm from "src/pages/forgot-password/ResetPasswordForm";
import ManagePayments from "src/pages/ManagePayments";
import CustomModal from "src/components/shared/CustomModal";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Header = ({ isMenu, setIsMenu, menuType, menus = [], publicGroup = false }) => {
  const { workspace_id } = useParams();
  const { group: group_id, page: page_id } = useUrlQueryParams();
  const navigate = useNavigate();
  const { site } = useContext(SiteContext);
  const { user } = useContext(AuthContext);
  const { list: workspaces, details: workspaceDetails, setDetails: setWorkspaceDetails, setLoading: setWorkspaceDetailsLoading, workspacePaymentModalOpen, setWorspacePaymentModalOpen = () => {}, planDetails, setPlanDetails, setIsPlanDetailsLoaded } = useContext(WorkspaceContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentLogo, setCurrentLogo] = useState("");
  const [userMenu, setUserMenu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [switchAdminButtonDisabled, setSwitchAdminButtonDisabled] = useState(false);

  const passwordValidation = !password || !confirmPassword || !oldPassword || password !== confirmPassword || !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[^\s]{8,}$/.test(password);

  const logout = () => {
    logoutRedirect();
  };

  const onHandleSwitchToAdmin = async () => {
    try {
      setSwitchAdminButtonDisabled(true);
      const { data } = await apiRequest("get", "/portal/users/self/switch-to-admin");
      window.open(data?.data, "_blank");
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSwitchAdminButtonDisabled(false);
    }
  };

  const navigateToPaymemnts = () => {
    setWorspacePaymentModalOpen(true);
  };

  const loadPlanDetails = async (workspaceId, refresh = false) => {
    try {
      if (!refresh) {
        setIsPlanDetailsLoaded(false);
        setWorspacePaymentModalOpen(false);
        setPlanDetails(null);
      }
      setLoading(true);
      let url = `/portal/workspaces/${workspaceId}/billing/plan`;
      if (publicGroup) url = `${url}?workspace=${workspaceId}&group=${group_id}&page=${page_id}`;

      const { data } = await apiRequest("get", url, {}, { returnErrorWithstatus: true });
      setPlanDetails(data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setPlanDetails(null);
      if (err?.status === 402) setWorspacePaymentModalOpen(true);
    } finally {
      setIsPlanDetailsLoaded(true);
    }
  };

  useEffect(() => {
    loadPlanDetails(workspace_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id]);

  useEffect(() => {
    if (planDetails?.active_plan?.plan_details?.pricing?.type !== "no_charge" && (["on_trial"].includes(planDetails?.active_plan?.subscription_status) || planDetails?.active_plan?.payment_required)) {
      setWorspacePaymentModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails?.active_plan]);

  useEffect(() => {
    setUserMenu({
      image: UserImage,
      name: "Administrator",
      dropdownData: [
        {
          _id: 1,
          icon: KeyIcon,
          label: "Change password",
          standout: false,
          onclick: () => setIsOpenModal(true),
        },
        {
          _id: 2,
          icon: WrenchScrewdriverIcon,
          label: "Switch to Admin",
          disabled: switchAdminButtonDisabled,
          standout: false,
          hidden: !["admin", "editor"]?.includes(user?.type),
          onclick: onHandleSwitchToAdmin,
        },
        {
          _id: 3,
          icon: BanknotesIcon,
          label: "Manage Payments",
          standout: false,
          hidden: !(workspaceDetails?.billing_elegible_user_id === user?._id),
          onclick: navigateToPaymemnts,
        },
        {
          _id: 4,
          icon: ArrowRightStartOnRectangleIcon,
          label: "Logout",
          onclick: logout,
          standout: false,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.type, switchAdminButtonDisabled, window.location.pathname, workspaceDetails, user?._id]);

  const clearChangePasswordModalValues = () => {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (!isOpenModal) {
      clearChangePasswordModalValues();
    }
  }, [isOpenModal]);

  const changePassword = async () => {
    setDisabled(true);

    try {
      const { data: resData } = await apiRequest("post", "/portal/user/self/change-password", {
        body: {
          old_password: oldPassword,
          password,
          confirm_password: confirmPassword,
        },
      });

      if (resData.status === 200) {
        toast.success(resData.message);
        setIsOpenModal(false);

        clearChangePasswordModalValues();
      } else {
        toast.error(resData.message);
        clearChangePasswordModalValues();
      }
    } catch (err) {
      toast.error(err.message);
      clearChangePasswordModalValues();
    } finally {
      setDisabled(false);
    }
  };

  const clickWorkspace = async (workspace = {}, from = "workspace") => {
    let workspaceId = workspace?._id;

    if (!workspaceId) {
      throw new Error("No workspace available to fetch.");
    }

    if ("" + workspaceId === "" + workspaceDetails?._id && from !== "payments") {
      return;
    }

    // set the workspace, group and page to localstorage
    localStorage.setItem("workspace", workspace?.slug || workspace?._id || "");
    localStorage.setItem("group", "");
    localStorage.setItem("page", "");

    setIsPlanDetailsLoaded(false);
    setWorkspaceDetailsLoading(true);

    try {
      const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceId}/page`, {}, { returnErrorWithstatus: true });

      if (resData.status === 200) {
        if (workspaceDetails._id !== workspace._id) {
          setWorkspaceDetails(workspace);
        }
        navigate(resData.link);
      } else {
        navigate("/permission-denied");
      }
    } catch (err) {
      navigate("/permission-denied");
    } finally {
      setWorkspaceDetailsLoading(false);
    }
  };

  function extractWorkspaceName() {
    // Assuming the base structure of the URL is "http://localhost:3002/workspace/{workspace-name}/..."
    const url = window.location.href;
    const pathSegments = url.split("/");

    const workspaceIndex = pathSegments.findIndex((segment) => segment === "workspace");

    if (workspaceIndex !== -1 && workspaceIndex < pathSegments.length - 1) {
      const workspaceName = pathSegments[workspaceIndex + 1];
      return workspaceName;
    }

    return null;
  }

  function loadImage() {
    // let src = site?.image_logo ? `${site.image_logo}?${new Date().getTime()}` : Logo;
    let src = site?.image_logo ? site.image_logo : "";
    let setting = site;
    if (workspace_id && !workspaceDetails?.use_global_settings) {
      // src = workspaceDetails?.image_logo ? `${workspaceDetails.image_logo}?${new Date().getTime()}` : Logo;
      src = workspaceDetails?.image_logo ? workspaceDetails.image_logo : "";
      setting = workspaceDetails;
    }

    setCurrentLogo(src);
    setBrowserStyleProperties(document, setting);
  }

  useEffect(() => {
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, site?._id, workspace_id]);

  return (
    <>
      <div
        className={classNames(
          " fixed left-0 top-0 z-30 flex h-16 w-full items-center bg-headerColor transition-all",
          (workspaceDetails.workspace_type !== "JWT_FULL_EMBED" || !isDarkColor(workspaceDetails.top_bar_color)) && !workspacePaymentModalOpen ? "border-b border-gray-200" : "",
        )}>
        <div className={classNames("relative flex h-full w-full items-center justify-between pr-6")}>
          <div className={classNames("flex items-center")}>
            {!(!workspaceDetails?._id || !menus?.length || menus?.length === 1) && (
              <button
                onClick={() => setIsMenu(!isMenu)}
                className={classNames("group group ml-2 flex h-9 w-9 items-center justify-center rounded-md transition-all", menuType === "vertical" ? "" : "md:hidden")}>
                <Bars3BottomLeftIcon className="h-7 w-7 text-headerTextColor transition-all group-hover:stroke-2" />
              </button>
            )}
            {workspace_id && workspaceDetails._id && workspaces.length > 1 ? (
              <div className={classNames(menuType === "vertical" && menus?.length === 1 && "pl-2", menus?.length > 1 && menuType === "horizontal" && "pl-2")}>
                <DropdownWrapper
                  data={workspaces.map((workspace) => ({
                    ...workspace,
                    image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
                  }))}
                  onClick={clickWorkspace}
                  currentId={extractWorkspaceName()}
                  content={
                    <>
                      {currentLogo ? (
                        <div className="mb-[1px] flex h-12 py-[2px] sm:h-14">
                          <Image
                            src={currentLogo}
                            alt={"logo"}
                            width="100%"
                            height="100%"
                            effect="blur"
                            classes="object-contain object-left max-h-full max-w-full !h-auto !w-auto"
                            hideImageOnError
                          />
                        </div>
                      ) : (
                        <div className="flex h-full w-full items-center justify-center rounded border border-headerColor bg-headerColor px-3 py-2 font-medium text-headerTextColor transition-all hover:border-headerTextColor hover:shadow">
                          Switch workspace
                          <RectangleStackIcon className="ml-[8px] h-5 w-5" />
                        </div>
                      )}
                    </>
                  }
                  buttonLabel="Workspaces"
                  buttonClass="w-full max-w-sm"
                  buttonIcon={true}
                  dropdownContainer="ml-11"
                />
              </div>
            ) : (
              <div className="ml-2 h-12 sm:h-[52px] sm:py-[3px]">
                <Image
                  src={currentLogo}
                  alt={"logo"}
                  width="100%"
                  height="100%"
                  effect="blur"
                  classes="object-contain object-left"
                  hideImageOnError
                />
              </div>
            )}
          </div>
          {user?._id && (
            <div className="ml-auto">
              <UserDropdown
                data={userMenu}
                xPlacement={"right"}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Change Password"
        isOpen={isOpenModal}
        onSuccess={changePassword}
        onCancel={() => setIsOpenModal(false)}
        defaultOptions={{
          onSuccessLoaderVisible: disabled,
          onSuccessLoaderStart: disabled,
          successButtonDisabled: disabled || passwordValidation,
        }}>
        <div className="mb-3">
          <Input
            inline={true}
            inputValue={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            label={"Current Password *"}
            inputType={"password"}
            inputName={"password"}
          />
        </div>
        <hr className="my-5" />
        <ResetPasswordForm
          password={password}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
        />
      </Modal>
      <CustomModal
        size="xxl"
        title={planDetails?.active_plan ? (planDetails?.active_plan?.payment_required ? "Plan purchase" : "Manage Payments") : ""}
        hideImage={false || (planDetails && !planDetails?.active_plan?.payment_required)}
        secondaryTitle={workspaceDetails?.name || ""}
        hideCrossIcon={!planDetails?.active_plan || planDetails?.active_plan?.payment_required}
        onClose={() => setWorspacePaymentModalOpen(false)}
        isOpen={workspacePaymentModalOpen}>
        <ManagePayments
          loading={loading}
          loadPlanDetails={loadPlanDetails}
        />
      </CustomModal>
    </>
  );
};

export default Header;
